<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-cascades"></i> Clro銷售額與佣金(經銷)
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <el-form :inline="true"
                     :model="formInline"
                     class="demo-form-inline">
                <el-form-item label="客戶ID:">
                    <el-input v-model="formInline.merchantId"
                              placeholder="請以,區隔(如:18,24,11)"></el-input>
                </el-form-item>
                <el-form-item label="日期:">
                    <el-date-picker v-model="formInline.datetime"
                                    type="monthrange"
                                    range-separator="至"
                                    start-placeholder="開始月份"
                                    end-placeholder="結束月份">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary"
                               @click="handleSearch">查詢</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button @click="clearSearch">清除</el-button>
                </el-form-item>
            </el-form>
            <div class="pd-top-bottom">
                <el-button size="medium"
                           type="success"
                           @click="exportExcel">匯出表</el-button>
            </div>
            <div class="table-title">
                <h3>Clro經銷商</h3>
            </div>
            <TableTemplate :colums="colums"
                           v-loading="listLoading"
                           :data="tableData">
            </TableTemplate>
            <div class="table-title">
                <h3>Clro單獨客戶</h3>
            </div>
            <TableTemplate :colums="customerColums"
                           v-loading="listLoading"
                           :data="customerTableData">
            </TableTemplate>
        </div>
    </div>
</template>

<script>
import TableTemplate from '../../components/TableTemplate.vue'
import { commission, customerOrder, commissionCustomerOrderExport } from '../../api/index'
import { dateFormatMonth } from '@/utils/dateFormat.js'
export default {
    components: {
        TableTemplate,
    },
    data() {
        return {
            formInline: { datetime: [] },
            colums: [
                {
                    name: '月份',
                    prop: 'date',
                },
                {
                    name: '銷售額(USD)',
                    prop: 'retail',
                },
                {
                    name: '佣金(USD)',
                    prop: 'commission',
                },
            ],
            tableData: [],
            customerColums: [
                {
                    name: '年月',
                    prop: 'date',
                },
                {
                    name: '新購數',
                    prop: 'domain_reg',
                },
                {
                    name: '續約數',
                    prop: 'domain_renew',
                },
                {
                    name: '轉移數',
                    prop: 'domain_trans',
                },
                {
                    name: '銷售額(USD)',
                    prop: 'retail',
                },
                {
                    name: '佣金(USD)',
                    prop: 'commission',
                },
            ],
            customerTableData: [],
            listLoading: false,
        }
    },
    created() {
        this.getDateScope()
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            this.getCommission()
            this.getCustomerOrder()
        },
        getCommission() {
            this.listLoading = true
            if (this.formInline.datetime.length < 1) {
                this.getDateScope()
            }
            let data = {
                date_at: this.formInline.datetime ? dateFormatMonth(this.formInline.datetime[0]) : null,
                date_end: this.formInline.datetime ? dateFormatMonth(this.formInline.datetime[1]) : null,
            }
            commission(data).then((res) => {
                if (res.code == 300) {
                    this.tableData = res.data.data_info
                } else if (res.code == 500) {
                    this.$message.error(res.message)
                }
                this.listLoading = false
            })
        },
        getCustomerOrder() {
            this.listLoading = true
            if (this.formInline.datetime.length < 1) {
                this.getDateScope()
            }
            let data = {
                date_at: this.formInline.datetime ? dateFormatMonth(this.formInline.datetime[0]) : null,
                date_end: this.formInline.datetime ? dateFormatMonth(this.formInline.datetime[1]) : null,
                merchant_id: this.formInline.merchantId ? this.formInline.merchantId : null,
            }
            customerOrder(data).then((res) => {
                if (res.code == 300) {
                    this.customerTableData = res.data.data_info
                } else if (res.code == 500) {
                    this.$message.error(res.message)
                }
                this.listLoading = false
            })
        },
        handleSearch() {
            this.getData()
        },
        clearSearch() {
            this.formInline = { datetime: [] }
        },
        exportExcel() {
            if (this.formInline.datetime.length < 1) {
                this.getDateScope()
            }
            let data = {
                date_at: this.formInline.datetime ? dateFormatMonth(this.formInline.datetime[0]) : null,
                date_end: this.formInline.datetime ? dateFormatMonth(this.formInline.datetime[1]) : null,
                merchant_id: this.formInline.merchantId ? this.formInline.merchantId : null,
            }
            commissionCustomerOrderExport(data).then((res) => {
                this.excelTake(res, '匯出Clro銷售額與佣金')
            })
        },
        //接收excel
        excelTake(res, title) {
            const xlsx = 'application/vnd.ms-excel'
            const blob = new Blob([res], { type: xlsx })
            const fileReader = new FileReader()
            let _this = this
            fileReader.readAsText(blob, 'utf-8')
            fileReader.onload = function () {
                if (res.type === 'application/json') {
                    let msg = JSON.parse(fileReader.result).message
                    if (JSON.parse(fileReader.result).code == 500) {
                        _this.$message.error(msg)
                    }
                } else {
                    const a = document.createElement('a') // 轉換完成，建立一個a標籤用於下載
                    a.download = `${title}.xlsx`
                    a.href = window.URL.createObjectURL(blob)
                    a.click()
                    a.remove()
                }
            }
        },
        //預設日期範圍
        getDateScope() {
            let date = new Date(),
                y = date.getFullYear(),
                m = date.getMonth()
            let firstDay = new Date(y, m)
            this.formInline.datetime.push(firstDay)
            this.formInline.datetime.push(firstDay)
        },
    },
}
</script>

<style lang="scss" scoped>
.table-title {
    padding: 20px 0;
}
</style>